import axiosInstance from '@/libs/axiosInstance'

export default {
  async loadData(url, dataIndent) {
    const response = await axiosInstance.get(url)
    let data = null

    switch (dataIndent) {
      case 1:
        data = response.data
        break
      case 2:
        data = response.data.data
        break
      case 3:
        data = response.data.data.data
        break
      case 4:
        data = response.data.data.data.data
        break
      default:
    }

    return data
  },
}

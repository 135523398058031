// eslint-disable-next-line import/no-cycle
import router from '@/router'
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import AxiosConfig from '@/services/AxiosConfigs'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_HEROAI_API,
  withCredentials: true,
})

axiosInstance.interceptors.request.use(config => {
  const token = store.getters['heroAiAuthentications/sanctumToken']
  const { headers } = AxiosConfig.getJsonWithToken(token)

  const newConfig = { ...config }
  newConfig.headers = {
    ...headers,
  }

  return newConfig
}, error => Promise.reject(error))

axiosInstance.interceptors.response.use(response => response,
  async error => {
    if (error.response.status === 401) {
      await router.push({ name: 'heroai-logout' })
    }
    return Promise.reject(error)
  })

export default axiosInstance
